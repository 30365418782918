import { type FC, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from 'routes/AppRoutes';
import FullScreenSpinner from './components/atoms/FullScreenSpinner.tsx';

const router = createBrowserRouter(AppRoutes);

const App: FC = () => {
  return (
    <>
      <Helmet>
        <title>サイト分析、レポートはGrowth Support</title>
      </Helmet>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Suspense fallback={<FullScreenSpinner />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
};

export default App;
